import "@lottiefiles/lottie-player";
import { graphql, Link } from "gatsby";
import React, { Component, createRef } from "react";
import ArrowRight from "../assets/icons/arrow-right-alt.svg";
import {
  HowToWorkWithUsSection,
  Layout,
  OurWorkSection,
  PortfolioCardGrid,
  SEOTags,
  TechStackSection,
  WhatWeDoSection,
  WhoWeAreSection,
  WorkingWithUsSection,
} from "../components";
import * as styles from "../styles/home.module.scss";
const _ = require("lodash");

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = { disableLeftScroll: true, disableRightScroll: false };
    this.scrollRef = createRef(null);
  }

  cleanRelativeDir = (dir) => {
    const substrings = dir.split("/");
    const subdir = substrings[substrings.length - 1];
    const title = subdir.split("_")[1].replaceAll("-", " ").toUpperCase();
    return title;
  };

  render() {
    const tabClassArray = ["", styles.dataengg, styles.design, styles.devops];
    const techIcons = _(this.props.data.techIcons.nodes)
      .groupBy((tab) => tab.relativeDirectory)
      .map((value, key) => ({
        title: key,
        content: value,
      }))
      .value()
      .sort((a, b) => a.title.localeCompare(b.title))
      .map((item, index) => ({
        title: this.cleanRelativeDir(item.title),
        className: tabClassArray[index] ?? "",
        content: item.content,
      }));

    const portfolio = this.props.data.projectIcons.nodes.map((item) => {
      item.text = "READ MORE";
      return item;
    });

    const ourWork = this.props.data.portfolioMdx.nodes.map((item, index) => ({
      text: item.childMdx.body,
      industry: item.childMdx.frontmatter.title,
      publicURL: this.props.data.portfolioIcons.nodes[index].publicURL,
    }));

    return (
      <Layout layoutClassName="home-page">
        <div className={styles.homepage}>
          {/* <div className={`${styles.heroAnimation} ${styles.computer}`}>
            <lottie-player
              src="/lottie/Home-hero.json"
              autoplay
              loop
              preserveAspectRatio="xMidYMid slice"
              style={{
                height: "-webkit-fill-available",
                width: "-webkit-fill-available",
              }}
            ></lottie-player>
          </div>
          <div className={`${styles.heroAnimation} ${styles.mobile}`}>
            <lottie-player
              src="/lottie/Home-mobile-hero.json"
              autoplay
              loop
              preserveAspectRatio="xMidYMid slice"
              style={{
                height: "-webkit-fill-available",
                width: "-webkit-fill-available",
              }}
            ></lottie-player>
          </div> */}
          <section className={styles.hero}>
            <p className={styles.title}>Innovative solutions built together.</p>
            <Link to="/contact/">
              <button>Work with us</button>
            </Link>
          </section>
          <WhoWeAreSection />
          <WorkingWithUsSection />
          <WhatWeDoSection />
          <OurWorkSection data={ourWork} />
          <TechStackSection techIcons={techIcons} />
          <section className={styles.portfolio}>
            <h6 className={styles.header}>PORTFOLIO</h6>
            <p className={styles.title}>
              We have a broad experience with clients from different industries.
            </p>
            <Link to="/portfolio/" className={styles.arrowLink}>
              SEE PORTFOLIO <ArrowRight />
            </Link>
            <PortfolioCardGrid
              cards={portfolio}
              urlPrefix="/portfolio"
              columns={2}
              overlay
            />
          </section>
          <HowToWorkWithUsSection />
        </div>
      </Layout>
    );
  }
}

export default Root;

export const Head = () => <SEOTags />;

export const query = graphql`
  query {
    techIcons: allFile(
      filter: { relativeDirectory: { regex: "/assets/icons/tech/(.*)/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        publicURL
        name
        relativeDirectory
      }
    }
    industryIcons: allFile(
      filter: { relativeDirectory: { eq: "assets/icons/industry" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        name
        publicURL
      }
    }
    portfolioIcons: allFile(
      filter: {
        relativeDirectory: { regex: "/assets/portfolio/(.*)/" }
        extension: { eq: "svg" }
      }
      sort: { fields: relativeDirectory, order: ASC }
    ) {
      nodes {
        publicURL
      }
    }
    portfolioMdx: allFile(
      filter: {
        relativeDirectory: { regex: "/assets/portfolio/(.*)/" }
        extension: { eq: "mdx" }
      }
      sort: { fields: relativeDirectory, order: ASC }
    ) {
      nodes {
        childMdx {
          body
          frontmatter {
            title
          }
        }
      }
    }
    projectIcons: allFile(
      filter: {
        relativeDirectory: { eq: "assets/projects/cards" }
        extension: { eq: "svg" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        publicURL
      }
    }
  }
`;
